<template>
    <div style="display: flex;justify-content: space-between">
      <div>
        <el-button type="primary" :disabled="loading" @click="openDialog">选图</el-button>
      </div>
      <div>
        <el-select v-model="form.label" :disabled="loading" @change="formatData" style="width: 240px">
          <el-option v-for="l in labels" :value="l" :label="`方案${l}`"></el-option>
        </el-select>
        <el-button v-if="has_feature_rookie_apply" type="danger" :disabled="loading" @click="openApplyDialog" style="margin-left: 5px">应用</el-button>
      </div>
    </div>
    <template v-for="d in data">
        <el-card style="margin-top: 5px" shadow="never" :header="`第${d.day}天`" v-if="d" v-loading="loading">
            <vue-draggable-next v-model="d.images" :animation="300" handle=".handle" @change="resort(d.images)">
                <template v-for="rookie in d.images">
                    <div class="handle" style="display: inline-block;position: relative">
                        <el-image style="margin: 5px;height: 160px"
                                  :src="`${$root.cdn_url}/${rookie.thumbnail}`"></el-image>
                        <el-icon @click="remove(rookie)"
                                 style="position: absolute; top: 5px; right: 5px; cursor: pointer">
                            <CircleClose/>
                        </el-icon>
                    </div>
                </template>
            </vue-draggable-next>
        </el-card>
    </template>

    <el-table style="margin-top: 10px" v-loading="loading" :data="rookieFilter">
      <el-table-column label="预览图" prop="prefix" width="230">
        <template #default="scope">
          <el-image lazy :src="`${$root.cdn_url}/${scope.row.thumbnail}`"
                    style="width: 150px"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="ID" prop="image_id" width="180"></el-table-column>
      <el-table-column label="激励视频" width="150">
        <template #default="scope">
          <el-tag v-if="scope.row.video">{{"Yes"}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="天数" prop="day" width="180">
        <template #default="scope">
          <el-input-number v-model="scope.row.day" :disabled="loading"  @change="updateRookie(scope.row, true)"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column label="上线时间" prop="online_date" width="200"></el-table-column>
    </el-table>

    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%">
        <el-form :model="form" label-width="100px" ref="form">
            <el-form-item label="天数" prop="day" :rules="[{required: true, message: '输入天数', type: 'number'}]">
                <el-input-number v-model="form.day" :disabled="loading"></el-input-number>
            </el-form-item>
            <el-form-item label="图片" prop="image_id" :rules="[{required: true, message: '请选择图片'}]">
                <template v-if="image">
                    <el-image style="width: 160px" :src="`${$root.cdn_url}/${image.thumbnail}`"></el-image>
                </template>
            </el-form-item>
        </el-form>
        <div>
            <el-select :disabled="loading" v-model="query.category" clearable filterable style="width: 200px"
                       placeholder="分类">
                <el-option v-for="c in category_list" :label="`${c.i18n_name.EN}(${c.i18n_name.ZH_HANS})`"
                           :value="c.key"></el-option>
            </el-select>
<!--            <el-select :disabled="loading" v-model="query.module" clearable style="width: 100px" placeholder="类型">-->
<!--                <el-option v-for="(v, k) in $root.image_module_enum" :label="v" :value="parseInt(k)"></el-option>-->
<!--            </el-select>-->
            <el-select :disabled="loading" v-model="query.video" clearable style="width: 100px" placeholder="激励视频">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
            </el-select>
            <el-select :disabled="loading" v-model="query.online" clearable style="width: 200px" placeholder="已上线图片">
                <el-option label="是" :value="true"></el-option>
                <el-option label="否" :value="false"></el-option>
            </el-select>
            <el-input v-model="query.id" placeholder="ID" style="width: 220px"></el-input>
            <el-button type="success" :loading="loading" @click="fetchData(1)">查询</el-button>
        </div>
        <el-table :data="page" v-loading="loading" style="margin-top: 10px">
            <el-table-column label="ID" prop="id" width="250">
                <template #default="scope">
                    <div>{{ scope.row.id }}</div>
                    <div style="text-align: center">
                        <a :href="`${$root.cdn_url}/${scope.row.resource}`" target="_blank">查看原图</a>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="缩略图" prop="thumbnail" width="100">
                <template #default="scope">
                    <el-image :src="`${$root.cdn_url}/${scope.row.thumbnail}`" lazy style="width: 90px"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="模块" prop="module" width="100">
                <template #default="scope">
                    {{ $root.image_module_enum[scope.row.module] }}
                </template>
            </el-table-column>
            <el-table-column label="分类" prop="categories" width="200">
                <template #default="scope">
                    <template v-for="key in scope.row.categories">
                        <template v-for="c in category_list">
                            <el-tag v-if="c.key === key">{{ c.i18n_name.EN }}</el-tag>
                        </template>
                    </template>
                </template>
            </el-table-column>
            <el-table-column label="视频" prop="video" width="100">
                <template #default="scope">
                    {{ scope.row.video ? '是' : '' }}
                </template>
            </el-table-column>
            <el-table-column label="上线时间" prop="online_date" width="150"></el-table-column>
            <el-table-column label="操作" width="200">
                <template #default="scope">
                    <el-button type="primary" size="small" :disabled="loading"
                               @click="online(scope.row)">选择
                    </el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination layout="prev, pager, next" :total="total" @current-change="fetchData"
                       :page-size="query.limit"></el-pagination>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>

    <el-dialog v-model="dialog_opened_apply" width="30%" title="应用新手图">
      <el-form label-width="180px">
        <el-form-item label="选择要应用到哪些方案：">
          <el-checkbox-group v-model="this.form.target_labels" :disabled="loading">
            <el-checkbox v-for="i in this.labels" :key="i" :label="i">{{ `方案${i}` }}</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
      </el-form>
      <el-row style="justify-content: flex-end; margin-top: 10px">
        <el-button @click="reset()"  :loading="loading">取消</el-button>
        <el-button @click="applyRookie()"  type="primary" :loading="loading">确认</el-button>
      </el-row>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {VueDraggableNext} from 'vue-draggable-next'
import {has_feature_rookie_apply} from "../libs/permission";

export default {
    name: "Rookie",
    components: {VueDraggableNext},
    data() {
        return {
            loading: false, data: [], label: [], labels: [0, 1, 2], image: null,
            dialog_opened: false, rookie: [], category_list: [], page: [], total: 0,
            dialog_opened_apply: false,
            form: {
                label: 0,
                day: 0,
                image_id: '',
                country: "GLOBAL",
                platform: null,
                target_labels: [0, 1, 2],
            },
            query: {
                module: 1,
                online: true,
                skip: 0,
                limit: 10,
                video: null,
            },
        }
    },
    methods: {
      has_feature_rookie_apply,
        init() {
            this.loading = true;
            axios.get(`/cms/v1/rookie`).then(res => {
                this.rookie = res.data.data.rookieList;
            }).then(_ => {
                this.loading = false;
                this.formatData();
            })
        },
        openDialog() {
            this.dialog_opened = true;
            this.fetchData(1);
        },
        formatData() {
            this.label = [];
            this.data = [];
            this.rookie.forEach(r => {
                if (!this.label.includes(r.label)) {
                    this.label.push(r.label);
                }
                if (r.label === this.form.label) {
                    if (!this.data[r.day]) {
                        this.data[r.day] = {day: r.day, images: []}
                    }
                    this.data[r.day].images.push(r);
                }
            });
            this.data.forEach(d => {
                d.images.sort(function (a, b) {
                    return a.sort - b.sort;
                })
            })
        },
        reset() {
            if(this.$refs.form) {
              this.$refs.form.resetFields();
            }
            this.image = null;
            this.dialog_opened = this.loading = false;
            this.dialog_opened_apply = false
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    axios.post(`/cms/v1/rookie`, this.form).then(res => {
                        this.rookie.push(res.data.data);
                        this.init();
                        this.reset();
                    });
                }
            })
        },
        remove(row) {
            this.loading = true;
            axios.delete(`/cms/v1/rookie/${row.id}`).then(res => {
                this.init()
            });
        },
        addImage(image) {
            this.form.image_id = image.id;
        },
        resort(images) {
            const sort = [];
            images.forEach(i => {
                sort.push(i.id);
            });
            this.loading = true;
            axios.post(`/cms/v1/sort/rookie`, {sort}).then(res => {
                this.loading = false;
            })
        },
        fetchData(page) {
            this.query.skip = page - 1;
            this.loading = true;
            axios.get(`/cms/v1/image`, {params: this.query}).then(res => {
                this.page = res.data.data.imageList;
                this.total = res.data.data.total;
                this.loading = false;
            })
        },
        online(row) {
            this.image = row;
            this.form.image_id = row.id;
        },
        updateRookie(row, refresh) {
          if(refresh) {
            this.loading = true;
          }
          axios.put(`/cms/v1/rookie`, {
            image_id: row.image_id, day: row.day,
            label: this.form.label, country: this.form.country}).then(_ => {
            if(refresh) {
              this.loading = false
              this.init();
            }
          })
        },
        openApplyDialog() {
          this.dialog_opened_apply = true;
        },
        applyRookie() {
          this.loading = true;
          axios.post(`/cms/v1/apply/rookie`, {label: this.form.label,
            target_labels: this.form.target_labels.join(',')}).then(_ => {
            this.init();
            this.reset();
          });
        },
    },

    computed: {
      rookieFilter() {
        return this.rookie.filter(d => {
          if (!d.label) {
            d.label = 0
          }
          return d.label === this.form.label;
        })
      },
    },

    mounted() {
        this.loading = true;
        axios.all([
            axios.get('/cms/v1/category').then(res => {
                this.category_list = res.data.data.categoryList;
            }),
        ]).then(_ => {
            this.loading = false;
            this.init();
        })
    },
}
</script>

<style scoped>

</style>