import Index from './components/Index.vue';
import Rookie from './components/Rookie.vue';
import Story from './components/Story.vue';
import Category from './components/Category.vue';
import Carousel from './components/Carousel.vue';
import Update from './components/Update.vue';
import Popup from "./components/Popup.vue";
import Subscription from "./components/vip/Subscription.vue";
import NewbieSales from "./components/vip/NewbieSales.vue";
import Score from "./components/Score.vue";
import Performance from "./components/Performance.vue";


export const routes = [
    {
        name: 'Index',
        path: '/',
        component: Index,
    }, {
        name: 'Rookie',
        path: '/rookie',
        component: Rookie,
    }, {
        name: 'Story',
        path: '/story',
        component: Story,
    }, {
        name: 'Category',
        path: '/category',
        component: Category,
    }, {
        name: 'Update',
        path: '/update',
        component: Update,
    }, {
        name: 'Carousel',
        path: '/carousel',
        component: Carousel,
    }, {
        name: 'Popup',
            path: '/popup',
            component: Popup
    }, {
        name: 'Subscription',
            path: '/subscription',
            component: Subscription,
    }, {
        name: 'NewbieSales',
            path: '/newbie-sales',
            component: NewbieSales,
    }, {
        name: 'Score',
            path: '/score',
            component: Score,
    }, {
        name: 'Performance',
            path: '/performance',
            component: Performance,
    }
];