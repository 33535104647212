<template>
    <el-container>
        <el-header height="64px">
            <div style="display: flex">
                <div style="height: 64px">
                    <img style="margin: 16px 0;height: 32px;cursor: pointer;" @click="$router.push({name: 'Index'})"
                         src="https://play-lh.googleusercontent.com/2gUfdZFzMqqTBflaw-fZCjWbl2iKNn24lQP1yi_ucG6egXbmsavvMS_cDMF-zaUgGBAz=w240-h480-rw">
                </div>
                <div style="padding: 0 10px;line-height: 64px;font-size: 20px;color: var(--el-text-color-regular);">
                    ClassicJigsaw
                    <el-divider direction="vertical" style="font-size: 32px"></el-divider>
                </div>
            </div>
            <div class="dark-switcher">
                <el-switch style="margin: 16px" v-model="dark" @change="changeDark"
                           active-action-icon="Moon" inactive-action-icon="Sunny"></el-switch>
            </div>
        </el-header>
        <el-container style="background-color: var(--el-border-color-extra-light)" :style="{height: height + 'px'}">
            <aside-nav ref="nav">
                <aside-group title="素材库">
                    <aside-item v-if="!is_mi()" icon="Files" title="全局图库" index="Index"></aside-item>
                    <aside-item v-if="!is_mi()" icon="Reading" title="分类管理" index="Category"></aside-item>
                    <aside-item icon="Aim" title="新手引导" index="Rookie"></aside-item>
                    <aside-item icon="Orange" title="轮播图" index="Carousel"></aside-item>
                    <aside-item icon="Timer" title="每日上新" index="Update"></aside-item>
                    <aside-item icon="Collection" title="故事线" index="Story"></aside-item>
                </aside-group>
              <aside-group title="运营数据">
                <aside-item icon="Star" title="素材评分" index="Score"></aside-item>
                <aside-item icon="Clock" title="每日表现" index="Performance"></aside-item>
              </aside-group>
                <aside-group v-if="!is_mi()" title="运营活动">
                  <aside-item icon="Monitor" title="弹窗" index="Popup"></aside-item>
<!--                  <aside-item icon="HelpFilled" title="悬浮按钮" index="Suspend"></aside-item>-->
                </aside-group>
                <aside-group v-if="!is_mi()" title="内购">
                  <aside-item index="Subscription" title="订阅活动" icon="Goods"></aside-item>
                  <aside-item index="NewbieSales" title="新用户活动" icon="Goods"></aside-item>
                </aside-group>
            </aside-nav>
            <el-main :style="{padding: $route.name === 'Tag'?'0':'var(--el-main-padding)'}">
                <router-view></router-view>
            </el-main>
        </el-container>
    </el-container>
</template>
<script>
import {useDark, useToggle} from '@vueuse/core';
import AsideNav from "./base/AsideNav.vue";
import AsideGroup from "./base/AsideGroup.vue";
import AsideItem from "./base/AsideItem.vue";
import axios from "ts-axios-new";
import {is_mi} from "./libs/permission.ts";

export default {
    name: 'App',
    components: {AsideGroup, AsideItem, AsideNav},
    data() {
        return {
            dark: window.matchMedia('(prefers-color-scheme: dark)').matches,
            width: document.documentElement.clientWidth,
            height: document.documentElement.clientHeight - 64,
            media: window.matchMedia('(prefers-color-scheme: dark)'),
            country_list: [], cdn_url: import.meta.env.VITE_CDN_URL, authorization: '',
            image_module_enum: {1: 'Library', 2: 'Daily', 3: 'Story'},
            user: {
                user_id: '',
                avatar: '',
                name: '',
                email: '',
                access: [],
            },
        }
    },
    methods: {
      is_mi,
        changeDark() {
            useToggle(useDark())(this.dark);
        },
        handleScroll() {
            this.height = document.documentElement.clientHeight - 64;
            this.width = document.documentElement.clientWidth;
        },
    },
    mounted() {
        window.onresize = _ => {
            this.handleScroll();
        };
        this.media.addEventListener('change', e => {
            this.dark = e.matches;
            this.changeDark();
        });
        this.changeDark();
        axios.get('https://console.plutodesk.com/api/v1/country').then(res => {
            this.country_list = res.data.data.countryList;
        });
    },
}
</script>
<style>
html, body {
    margin: 0;
    padding: 0;
}

.el-header {
    display: flex;
    padding: 0 20px;
    justify-content: space-between;
    border-bottom: solid 1px var(--el-border-color-light);
    background-color: var(--el-bg-color);
}
</style>
<style scoped>
.dark-switcher ::v-deep(.el-switch__action) {
    color: #000 !important;
}
.dark-switcher ::v-deep(.el-switch.is-checked .el-switch__core) {
    background-color: #444;
    border-color: #444;
}
</style>