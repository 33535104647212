<template>
    <div>
        <el-button type="primary" :disabled="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table style="margin-top: 10px" v-loading="loading" :data="data">
        <el-table-column label="ID" width="150" prop="id"></el-table-column>
        <el-table-column label="图片" width="300px" prop="image">
            <template #default="scope">
                <el-image lazy :src="`${$root.cdn_url}/${scope.row.resource}`"
                          style="width: 250px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="上线" width="100px" prop="active">
            <template #default="scope">
                <el-switch v-model="scope.row.active" :inactive-value="false" :active-value="true"
                           @change="update(scope.row)"></el-switch>
            </template>
        </el-table-column>
        <el-table-column label="动作" prop="action" width="200">
            <template #default="scope">
                {{ carousel_action_enum[scope.row.action] }}
            </template>
        </el-table-column>

        <el-table-column label="新手" width="150">
          <template #default="scope">
            <el-tag v-if="scope.row.rookie">新手活动</el-tag>
          </template>
        </el-table-column>

        <el-table-column label="背景色" prop="color" width="100"></el-table-column>
        <el-table-column label="操作" width="250">
            <template #default="scope">
                <el-button type="success" circle icon="Top" :loading="loading"
                           :disabled="scope.$index===0" @click="moveUp(scope.$index)"></el-button>
                <el-button type="success" circle icon="Bottom" :loading="loading"
                           :disabled="scope.$index===data.length - 1" @click="moveDown(scope.$index)"></el-button>
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button size="small" type="danger" :disabled="loading" @click="remove(scope.row)">删除</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="图片" prop="resource" :rules="[{required: true, message: '请上传图片'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}">
                    <el-image v-if="form.resource" :src="`${$root.cdn_url}/${form.resource}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="动作" prop="action" :rules="[{required: true, message: '请选择动作'}]">
                <el-select v-model="form.action" :disabled="loading" @change="changeAction">
                    <el-option v-for="(v, k) in carousel_action_enum" :label="v" :value="parseInt(k)"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="订阅活动" prop="data.id" :rules="[{required: false, message: '请选择订阅活动'}]"
                          v-if="[3].includes(form.action)">
              <el-select v-model="subscriptionValue" :disabled="loading" filterable clearable>
                <el-option v-for="c in this.subscription_list" :value="c.id" :label="c.event_name || c.sku"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Story" prop="data.story_id"
                          :rules="[{required: true, message: '请选择story'}]" v-if="form.action===2">
                <el-select v-model="form.data.story_id" :disabled="loading" v-if="form.data" filterable>
                    <el-option v-for="c in story_list" :label="c.i18n_name.EN" :value="c.id"></el-option>
                </el-select>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" :loading="loading" @click="reset" text>取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import {update, deepcopy} from "../libs/utils";
import {ElMessageBox} from 'element-plus'

export default {
    name: "Carousel",
    data() {
        return {
            loading: false, data: [], editing: null, dialog_opened: false, image_list: [], story_list: [],
            gallery_list: [], jigsaw_list: [], level_list: [],
            carousel_action_enum: {
                1: '进入当日Daily',
                2: '进入Story',
                3: '进入内购页',
            },
            form: {
                action: null,
                data: null,
                active: false,
                resource: '',
            },
        }
    },
    methods: {
        changeAction() {
            this.form.rookie = false
            if ([1].includes(this.form.action)) {
                this.form.data = null
            } else if (this.form.action === 2) {
                this.form.data = {
                    story_id: '',
                }
            } else if (this.form.action === 3) {
                this.form.data = {
                  id: '',
                }
            }
        },
        init() {
            this.loading = true;
            axios.all([
                axios.get('/cms/v1/carousel').then(res => {
                    this.data = res.data.data.carouselList;
                }),
                axios.get(`/cms/v1/image`).then(res => {
                    this.image_list = res.data.data.imageList;
                }),
                axios.get('/cms/v1/story').then(res => {
                    this.story_list = res.data.data.storyList;
                }),
                axios.get('/cms/v1/subscription-all').then(res => {
                  this.subscription_list = res.data.data.subscriptionList;
                }),

            ]).then(_ => {
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    update(this.form, item);
                    this.form.data = deepcopy(item.data);
                    this.editing = item;
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = null;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/carousel/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/carousel`, this.form).then(res => {
                            this.data.push(res.data.data);
                            this.reset();
                        });
                    }
                }
            })
        },
        remove(row) {
            ElMessageBox.confirm('确定要删除该项吗？', '提示', {
                cancelButtonText: '取消',
                confirmButtonText: '确定',
                type: 'warning',
            }).then(_ => {
                this.loading = true;
                axios.delete(`/cms/v1/carousel/${row.id}`).then(_ => {
                    this.data.splice(this.data.indexOf(row), 1);
                    this.loading = false;
                })
            }).catch(_ => {
            });
        },
        handleSuccess(res) {
            this.loading = false;
            this.form.resource = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/carousel/${row.id}`, row).then(_ => {
                this.loading = false;
            });
        },
        moveUp(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index - 1, 0, row[0]);
            this.resort();
        },
        moveDown(index) {
            const row = this.data.splice(index, 1);
            this.data.splice(index + 1, 0, row[0]);
            this.resort();
        },
        resort() {
            const sort = [];
            this.data.forEach(d => {
                sort.push(d.id);
            })
            this.loading = true;
            axios.post(`/cms/v1/sort/carousel`, {sort}).then(_ => {
                this.loading = false;
            })
        },
        validator(rule, value, callback) {
            if (/^#[A-Z0-9]{6}$/.test(value)) {
                callback();
            } else {
                callback('请选择或输入正确格式的颜色码');
            }
        },
    },
    computed: {
      subscriptionValue: {
        get() {
          return this.form.data.id
        },
        set(value) {
          if (value) {
            this.form.data.id = value
            this.form.rookie = false
            this.subscription_list.forEach((subscription) => {
              if (this.form.data.id === subscription.id) {
                this.form.data.start = subscription.start
                this.form.data.end = subscription.end
                this.form.data.sku = subscription.sku
                this.form.rookie = subscription.newbie
              }
            })
          } else {
            this.form.data = {id: ''}
            this.form.rookie = false
          }
        },
      }
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>