<template>
    <div>
        <el-select :disabled="loading" v-model="query.category" clearable filterable style="width: 200px"
                   placeholder="分类">
            <el-option v-for="c in category_list" :label="`${c.i18n_name.EN}(${c.i18n_name.ZH_HANS})`"
                       :value="c.key"></el-option>
        </el-select>
        <el-select :disabled="loading" v-model="query.module" clearable style="width: 150px" placeholder="模块">
            <el-option v-for="(v, k) in $root.image_module_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select :disabled="loading" v-model="query.video" clearable style="width: 150px" placeholder="激励视频">
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
        </el-select>

        <el-select :disabled="loading" v-model="query.online" clearable style="width: 150px" placeholder="是否上线">
          <el-option label="是" :value="true"></el-option>
          <el-option label="否" :value="false"></el-option>
        </el-select>

        <el-input v-model="query.id" placeholder="ID" @change="reload" style="width: 220px"></el-input>
        <date-range-picker v-model="query" start-placeholder="上线开始时间" end-placeholder="上线结束时间"
                           :disabled="loading" :disabledDate="disabledDate"></date-range-picker>
        <el-button type="success" :loading="loading" @click="init">查询</el-button>
        <el-button type="primary" :loading="loading" @click="openDialog()">新建</el-button>
    </div>
    <el-table :data="data" v-loading="loading" style="margin-top: 10px">
        <el-table-column label="ID" prop="id" width="250">
            <template #default="scope">
                <div>{{ scope.row.id }}</div>
                <div style="text-align: center">
                    <a :href="`${$root.cdn_url}/${scope.row.resource}`" target="_blank">查看原图</a>
                </div>
            </template>
        </el-table-column>
        <el-table-column label="缩略图" prop="thumbnail" width="200">
            <template #default="scope">
                <el-image :src="`${$root.cdn_url}/${scope.row.thumbnail}`" lazy style="width: 180px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="模块" prop="module" width="100">
            <template #default="scope">
                {{ $root.image_module_enum[scope.row.module] }}
            </template>
        </el-table-column>
        <el-table-column label="分类" prop="categories" width="200">
            <template #default="scope">
                <template v-for="key in scope.row.categories">
                    <template v-for="c in category_list">
                        <el-tag v-if="c.key === key">{{ c.i18n_name.EN }}</el-tag>
                    </template>
                </template>
            </template>
        </el-table-column>
        <el-table-column label="视频" prop="video" width="100">
            <template #default="scope">
                {{ scope.row.video ? '是' : '' }}
            </template>
        </el-table-column>
        <el-table-column label="上线时间" prop="online_date" width="150">
          <template #default="scope">
            <el-date-picker style="width: 120px" value-format="YYYY-MM-DD" v-model="scope.row.online_date"
                            @change="update(scope.row)" :clearable="false"></el-date-picker>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
            </template>
        </el-table-column>
    </el-table>
    <el-pagination layout="prev, pager, next" :total="total" @current-change="fetchData"
                   :page-size="query.limit"></el-pagination>
    <el-dialog v-model="dialog_opened" :before-close="reset">
        <el-form :model="form" ref="form" label-width="100px">
            <el-form-item label="分类" prop="categories" :rules="[{required: true, message: '请选择分类'}]">
                <el-select v-model="form.categories" :disabled="loading" multiple filterable>
                    <el-option v-for="c in category_list" :label="`${c.i18n_name.EN}(${c.i18n_name.ZH_HANS})`"
                               :value="c.key"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="模块" prop="module">
                <el-radio-group v-model="form.module" :disabled="loading">
                    <el-radio v-for="(v, k) in $root.image_module_enum" :label="parseInt(k)">{{ v }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="激励视频" prop="video">
                <el-radio-group v-model="form.video" :disabled="loading">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="图片" prop="resource" :rules="[{required: true, message: '请上传图片'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleSuccess"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress" :disabled="loading"
                           :headers="{authorization: $root.authorization}">
                    <el-image v-if="form.resource" :src="`${$root.cdn_url}/${form.resource}`" lazy
                              style="width: 200px"></el-image>
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
</template>

<script>
import axios from "ts-axios-new";
import DateRangePicker from "../base/DateRangePicker";
import {update} from "../libs/utils";

export default {
    name: "Index",
    components: {DateRangePicker},
    data() {
        return {
            loading: false, data: [], editing: null, total: 0, dialog_opened: false, category_list: [],
            query: {
                category: null,
                module: null,
                start: null,
                end: null,
                skip: 0,
                limit: 100,
                video: null,
                online: null,
            },
            form: {
                categories: [],
                module: 1,
                resource: null,
                video: false,
                online_date: null,
            },
        }
    },
    methods: {
        init() {
            this.skip = 0;
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/image`, {params: this.query}).then(res => {
                    this.data = res.data.data.imageList;
                    this.total = res.data.data.total;
                }),
                axios.get(`/cms/v1/category`).then(res => {
                    this.category_list = res.data.data.categoryList;
                })
            ]).then(_ => {
                this.loading = false;
            })
        },
        fetchData(page) {
            this.query.skip = page - 1;
            this.loading = true;
            axios.get(`/cms/v1/image`, {params: this.query}).then(res => {
                this.data = res.data.data.imageList;
                this.total = res.data.data.total;
                this.loading = false;
            })
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                this.editing = item;
                update(this.form, item);
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.dialog_opened = this.loading = false;
            this.editing = null;
            this.form.categories = [];
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/image/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/image`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        handleProgress() {
            this.loading = true;
        },
        handleSuccess(res) {
            this.form.resource = res.data.name;
            this.loading = false;
        },
        update(row) {
          this.loading = true;
          axios.put(`/cms/v1/image/${row.id}`, row).then(_ => {
            this.loading = false;
          })
        },
        reload() {
          if (!this.query.id || this.query.id.length === 24) {
            this.skip = 0;
            this.page = 1;
            this.init();
          }
        },
        disabledDate(time) {
            return time.getTime() < 1630425600000
        },
    },
    mounted() {
        this.init();
    }
}
</script>

<style scoped>

</style>